import {apiFetch} from "../swClient";

function checkChildren(children) {
	let validChild = false;

	children.forEach((child) => {
		if(child.type !== 'link' || (child.type === 'link' && child.linkType !== null)) {
			validChild = true;
		}
	});

	return validChild;
}

function formatSWNavigationData(navigationData) {
	let cleanedNavigationData = [];

	if(!Array.isArray(navigationData)) {
		navigationData = Object.values(navigationData);
	}

	navigationData.forEach((menuItem) => {
		let link = '';

		if(menuItem.type === 'link') {
			if(menuItem.linkType === null) {
				return;
			}

			if(menuItem.linkType === 'external') {
				link = menuItem.externalLink;
			} else {
				if(menuItem.linkType === 'category') {
					link = menuItem.internalLink;
				}
			}
		} else {
			if(menuItem.type === 'folder' && (menuItem.children.length === 0 || !checkChildren(menuItem.children))) {
				return;
			}

			link = '/' + (menuItem.seoUrls.length > 0 ? menuItem.seoUrls[0].seoPathInfo : 'navigation/' + menuItem.id)
		}

		cleanedNavigationData.push({
			id: menuItem.id,
			parentId: menuItem.parentId,
			name: menuItem?.translated?.name || menuItem.name,
			type: menuItem.type,
			media: menuItem.media ? menuItem.media.url : false,
			url: link,
			order: menuItem?.customFields?.category_order || 0,
			children: formatSWNavigationData(menuItem.children).sort((a, b) => {
				if(!a.order) {
					return 0;
				} else {
					return  a.order > b.order ? 1 : -1
				}
			})
		});
	});

	return cleanedNavigationData;
}

export default {
	get: async function (categoryId, req) {
		const navigationBody = {
			associations: {
				seoUrls: {}
			},
			buildTree: true,
			depth: 1
		}

		let navigationRes = await apiFetch('navigation/main-navigation/' + categoryId, 'post', navigationBody, req);

		if(navigationRes.status === 'error') {
			return [];
		}

		return formatSWNavigationData(navigationRes.data);
	}
}
