
import CartIcon from 'public/images/icons/varukorg-thin.svg';
import React, { useContext } from 'react';
import Link from 'next/link';
import { TrackingContext } from 'components/providers/tracking';
import { makeStyles } from "@material-ui/styles";
import theme from "../theme";

const useStyles = makeStyles(
	() => ({
		label: {
			padding: theme.spacing(0.5),
			fontFamily: theme.typography.headerFont,
			fontSize: 14,
			textAlign: 'right'
		},
		cartIcon: {
			display: 'block',
			width: 16,
			height: 16
		},
		footer: {
			backgroundColor: '#eee !important',
			padding: '4px 8px',
			fontFamily: '"Times New Roman", serif',
			fontSize: 14,
			textAlign: 'center',
			borderRadius: 0,
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center'
		}
	}),
	{ name: 'MasonryProductItem' }
);

export default function MasonryProductItem({
	id,
	productNumber,
	name,
	image,
	link,
	price,
	categoryBreadcrumb,
	manufacturer,
	available = true,
	list,
	position,
	clickCallback
}) {
	const classes = useStyles();
	const { actions } = useContext(TrackingContext);

	function onClickTracking() {
		actions.productClick({
			id: productNumber,
			name: name,
			price: price,
			discount: 0,
			brand: manufacturer,
			category: categoryBreadcrumb,
			list,
			variant: '',
			quantity: 1
		});

		if (typeof clickCallback !== 'undefined') {
			clickCallback(productNumber);
		}
	}

	return available ? (
		<Link href={link} id={id}>
			<a style={{ display: 'block', textDecoration: 'none', color: 'inherit' }} onClick={onClickTracking}>
				{image}
				<div className={classes.footer} style={{ justifyItems: 'space-between' }}>
					<CartIcon className={classes.cartIcon} />
					<div className={classes.label} square elevation={0}>
						{name}
					</div>
				</div>
			</a>
		</Link>
	) : (
		''
	);
}
