import {Button, List, ListItem} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import theme from "../../../theme";
import {getCMSSettings, getCMSSlot, getCMSConfig} from "tools/cmsBlockSettings";
import Link from "next/link";
import React from "react";
import {useRouter} from "next/router";
import {useTranslation} from "tools/i18n";

const useStyles = makeStyles({
	buttonWrapper: {
		display: 'flex',
		position: 'relative',
		justifyContent: 'center',
		overflowX: 'auto',
		[theme.breakpoints.down('xs')]: {
			marginBottom: '40px !important'
		},
	},
	navigationWrapper: {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: '#fff',
		boxShadow: theme.borderShadow,
		overflowX: 'auto',
		overflowY: 'visible',
	},
	navigationList: {
		display: 'flex',
		alignItems: 'center',
		paddingTop: 16,
    	paddingBottom: 16,
		minWidth: 0,
		[theme.breakpoints.up('md')]: {
			justifyContent: 'center'
		},
	},
	navigationButtonList: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		padding: '20px 7px 5px',
		minWidth: 0,
		'&.bg-wedding-flower-bottom': {
			paddingTop: 50
		},
		[theme.breakpoints.up('md')]: {
			justifyContent: 'center'
		},
		[theme.breakpoints.down('xs')]: {
			padding: '20px 9px 0px'
		}
	},
	navigationListItem: {
		width: 'auto',
		[theme.breakpoints.down('xs')]: {
			minWidth: '50%',
			flexGrow: 1
		}
	},
	navigationItem: {
		width: 'auto',
		padding: 14,
		fontSize: 12,
		fontWeight: 500,
		textTransform: 'uppercase',
    	letterSpacing: '0.05em',
		'& a ': {
			whiteSpace: 'nowrap',
			color: 'inherit',
		},
		'&:not(.active) a ': {
			textDecoration: 'none'
		},
	},
	navigationButton: {
		width: '100%',
		padding: '8px 20px',
		color: '#212121',
		backgroundColor: '#ffffff',
		borderRadius: 3,
		letterSpacing: 0.5,
		whiteSpace: 'nowrap',
		boxShadow: theme.boxShadow[1],
		'&.active': {
			backgroundColor: '#212121',
			color: 'white'
		},
		'.bg-wedding-flower-bottom &': {
			borderRadius: 0,
			boxShadow: 'none',
			border: '1px solid black'
		},
		[theme.breakpoints.down('xs')]: {
			padding: '10px 8px',
			fontSize: 13
		}
	},
	navigationButtonLink: {
		width: '100%',
		margin: 8,
		color: 'inherit',
		textDecoration: 'none',
		[theme.breakpoints.down('xs')]: {
			margin: 6
		}
	}
}, {name: 'CategoryMenu'});

export default function CategoryMenu({block, slots, breadcrumbs, category, categoryMenu, forceButton = false, hideSeeAll = false, cmsPage}) {
	const classes = useStyles();
	const router = useRouter();
	const {t} = useTranslation(['general']);
	const CMSSettings = getCMSSettings(block);
	const menuSettings = getCMSSlot('categoryMenu', slots);

	function isMatchingCategoryUrl(routerPath, categoryUrl) {
		let routerPathWithoutQuery = routerPath.split('?')[0];

		return routerPathWithoutQuery === categoryUrl;
	}

	function getUrl(item) {
		return item.type === 'link' ? (item.linkType === 'external' ? item.externalLink : '/' + item.internalLink) : item.seoUrls && item.seoUrls.length > 0 ? '/' + item.seoUrls[0].seoPathInfo : '/navigation/' + item.id;
	}

	function getNavigationLinks() {
		const menuItems = categoryMenu || false;

		if(!menuItems || menuItems.length < 1) {
			return '';
		}

		return (
			<List className={classes.navigationList} disablePadding>
				{Object.keys(menuItems).length > 0 && !hideSeeAll ? getSeeAllLink() : ''}
				{Object.keys(menuItems).map((itemKey, key) => {
					let item = menuItems[itemKey];
					let url = item.url || getUrl(item);

					return (
						<ListItem className={[classes.navigationItem, isMatchingCategoryUrl(router.asPath, url) ? 'active' : ''].join(' ')} key={key} disableGutters>
							<Link href={url} scroll={false}>{item?.translated?.name || item.name}</Link>
						</ListItem>
					);
				})}
			</List>
		);
	}

	function getNavigationButton() {
		const menuItems = categoryMenu || false;

		if(!menuItems || menuItems.length < 1) {
			return '';
		}

		return (
			<List className={[classes.navigationButtonList, CMSSettings.class].join(' ')} disablePadding>
				{ cmsPage?.type !== 'landingpage' && Object.keys(menuItems).length > 0 && !hideSeeAll ? getSeeAllButton() : '' }
				{Object.keys(menuItems).map((itemKey, key) => {
					let item = menuItems[itemKey];
					let url = item.url || getUrl(item);

					return (
						<ListItem classes={{root: classes.navigationListItem}} style={{padding: 0}} key={key} disableGutters>
							<Link href={url} scroll={false}>
								<a className={classes.navigationButtonLink}>
									<Button className={[classes.navigationButton, 'navigation-button', isMatchingCategoryUrl(router.asPath, url) ? 'active' : ''].join(' ')}>{item?.translated?.name || item.name}</Button>
								</a>
							</Link>
						</ListItem>
					);
				})}
			</List>
		);
	}

	function categoryButtons() {
		return forceButton || menuSettings?.config?.showAsButtons?.value === true;
	}

	function getSeeAllLink() {
		let parentCategory = false;

		if(category.childCount === 0) {
			let breadcrumbKeys = Object.keys(breadcrumbs);

			breadcrumbKeys.forEach((id) => {
				if (id === category.parentId) {
					parentCategory = {
						name: breadcrumbs[id].name,
						url: breadcrumbs[id].path
					}
				}
			});
		} else {
			parentCategory = {
				name: category.translated.name,
				url: '/' + (category.seoUrls.length > 0 ? category.seoUrls[0].seoPathInfo : 'navigation/' + category.id)
			}
		}

		return (
			<ListItem className={[classes.navigationItem, isMatchingCategoryUrl(router.asPath, parentCategory.url) ? 'active' : ''].join(' ')} key={'see-all' + category.id} disableGutters>
				<Link href={parentCategory.url} scroll={false}>{parentCategory.name}</Link>
			</ListItem>
		);
	}

	function getSeeAllButton() {
		let parentCategory = false;

		if(category.childCount === 0) {
			let breadcrumbKeys = Object.keys(breadcrumbs);

			breadcrumbKeys.forEach((id) => {
				if (id === category.parentId) {
					parentCategory = {
						name: breadcrumbs[id].name,
						url: breadcrumbs[id].path
					}
				}
			});
		} else {
			parentCategory = {
				name: category.translated.name,
				url: '/' + (category.seoUrls.length > 0 ? category.seoUrls[0].seoPathInfo : 'navigation/' + category.id)
			}
		}

		return (
			parentCategory ?
			<ListItem classes={{root: classes.navigationListItem}} style={{padding: 0}} key={'see-all' + category.id} disableGutters>
				<Link href={parentCategory.url} scroll={false}>
					<a className={classes.navigationButtonLink}>
						<Button className={[classes.navigationButton, 'navigation-button', isMatchingCategoryUrl(router.asPath, parentCategory.url) ? 'active' : ''].join(' ')}>{t('see-all')}</Button>
					</a>
				</Link>
			</ListItem> : ''
		);
	}

	return (
		<>
			{categoryButtons() ?
				<div className={classes.buttonWrapper} style={{
					marginBottom: CMSSettings.styles.marginBottom,
				}}>
					{getNavigationButton()}
				</div> : <div className={classes.navigationWrapper} style={{
					marginBottom: CMSSettings.styles.marginBottom,
				}}>{getNavigationLinks()}</div>
			}
		</>
	);
}
